import React from 'react';
import * as style from './Services.module.scss';
import {StaticQuery, Link, graphql} from 'gatsby';

const Services = () => {
	 return(
		 <div className={style.wrapper}>
		 		<div className={style.content}>
					<StaticQuery query={graphql`
						{
							allWpServices{
								edges{
									node{
										title
										services{
											info
											point1
											point2
											point3
											point4
										}
									}
								}
							}

						}

					`} render={props => (
						props.allWpServices.edges.map(service => (
							<div className={style.service}>
								<h3 className={style.title}>{service.node.title}</h3>
								<ul className={style.list}>
									<li className={style.listItem}>{service.node.services.point1}</li>
									<li className={style.listItem}>{service.node.services.point2}</li>
									<li className={style.listItem}>{service.node.services.point3}</li>
									<li className={style.listItem}>{service.node.services.point4}</li>
								</ul>
								<Link to='/services' className={style.button}>Learn More</Link>
							</div>
						))

					)}
					/>
				</div>
		 </div>
	 )
}
export default Services;
