import * as React from "react"
import {StaticQuery, graphql} from 'gatsby';
import Layout from '../components/Layout/Layout';
import Hero from '../components/Hero/Hero';
import TextDiagonalImage from '../components/TextDiagonalImage/TextDiagonalImage';
import Services from '../components/Services/Services';
import Players from '../components/Players/Players';

//Initalizing Amplify
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

// markup
const IndexPage = () => {
  return (
    <main>
      <title>Home Page</title>
      <Layout>
        <StaticQuery query={graphql`
          {
            allWpContent(filter: { title: {eq: "Home Page"}}) {
             edges {
               node{
                 homePage{
                   mainImage{
                     gatsbyImage(width: 1500, placeholder: BLURRED)
                   }
                   philosophy
                   philosophyImage{
                     gatsbyImage(width: 800, placeholder: BLURRED)
                   }
                 }
               }
             }
           }
         }
        `} render={props => (
          props.allWpContent.edges.map(info => (
            <>
              <Hero
                image={info.node.homePage.mainImage.gatsbyImage}
                text={'Everything is possible with discipline and sacrifice.'}
                button={'Contact Us Now'}
                url={'/contact'}
                />
              <TextDiagonalImage
                title={'Philosophy'}
                text={info.node.homePage.philosophy}
                buttonText={'Read More'}
                image={info.node.homePage.philosophyImage.gatsbyImage}
              />
            </>
          ))
        )}
        />
        <Services />
        <Players />
      </Layout>
    </main>
  )
}

export default IndexPage
