import React, {useState} from 'react';
import * as style from './Players.module.scss';
import {StaticQuery, Link, graphql} from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const Players = ({type='elite', number=3}) => {
	const [count, setCount] = useState(0);

	const renderPlayer = (player) => {
		if(true) {
			return (
				<div className={style.player}>
					<GatsbyImage image={player.image.gatsbyImage} className={style.image} alt={player.name}/>
					<div className={style.info}>
						<h3 className={style.name}>{player.name}</h3>
						<div className={style.team}>{player.team}</div>
						<p className={style.quote}>{player.quote}</p>
					</div>
				</div>
			)
		}
	}

	 return(
		 <div className={style.players}>
		 	<StaticQuery query={graphql`
				{
					allWpPlayer (filter: {player: {category: {eq: "elite"}}}, limit: 3){
					 edges{
						 node{
							 player{
								 name
								 position
								 team
								 quote
								 category
								 image{
									 gatsbyImage(width: 800, placeholder: BLURRED)
								 }
							 }
						 }
					 }
				 }
			 }
			`} render={props => (
				props.allWpPlayer.edges.map(player => (
					<>	
						{
							player.node.player.category === type &&
								renderPlayer(player.node.player)
						}
						
					</>
				))
			)} />
		 </div>
	 )
}
export default Players;
