import React from 'react';
import * as style from './Hero.module.scss';
import {Link} from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const Hero = ({image, text, button, url}) => {
	 return(
		 <div className={style.wrapper}>
		 	<div className={style.cta}>
				<div className={style.heading}>
				 {text}
			 </div>
			 <Link to={url} className={style.button}>
					 {button}
			 </Link>
			 <div className={style.buttonStyle}></div>
			</div>
		 	<GatsbyImage image={image} className={style.image} alt='' />

		 </div>
	 )
}
export default Hero;
