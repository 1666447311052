import React from 'react';
import * as style from './TextDiagonalImage.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const TextDiagonalImage = ({title, text, image, buttonText, url}) => {
	 return(
		 <div className={style.wrapper}>
			 	<div className={style.textWrapper}>
					<div>
						<h3>{title}</h3>
						<p className={style.text}>
							{text}
						</p>
						<Link to='/philosophy' className={style.button}>
							{buttonText}
						</Link>
					</div>
				</div>
				<GatsbyImage image={image} className={style.image} alt=""/>

		 </div>
	 )
}
export default TextDiagonalImage;
